// eslint-disable-next-line no-redeclare,no-unused-vars
function buildGlobalSearch(cont) {
  var search = $('<div class="search" role="search"></div>').appendTo(cont);
  buildField(search, {
    field: 'search',
    placeholder: 'Suchen',
    widthFix: true,
    ident: 'global',
    save: function (value) {
      if (!value) return;
      dashboard.show();
      ajax({
        object: 'user',
        send: {func: 'searchAll', search: value},
        callback: function (xhr) {
          if (xhr.warning) return;
          if (!xhr.result) {
            buildPopup({content: info.warning.emptySearch});
            return;
          }

          // build view
          var view = buildView({
            type: 'search',
            title: 'Suchergebnisse',
            overview: true,
          });
          view.right.addClass('d-none');
          view.body = $(
            '<div id="search_body" class="view-body scrollable" data-mdb-perfect-scrollbar="true"></div>'
          ).appendTo(view.main);
          new mdb.PerfectScrollbar(view.body[0], {suppressScrollX: true});

          // add navigator
          var contNav = $(
            '<div class="view-nav" data-mdb-perfect-scrollbar="true"></div>'
          ).appendTo(view.left);
          new mdb.PerfectScrollbar(contNav[0], {suppressScrollX: true});
          view.nav = $(
            '<ul class="nav flex-column nav-pills gap-1"></ul>'
          ).appendTo(contNav);

          // add table cards
          $.each(xhr.result, function (ident, ids) {
            var object = ident;
            var objectSub = '';
            if (str_contains(object, '_'))
              [object, objectSub] = object.split('_');
            var cardId = 'search_' + ident;

            var cardCont = $(
              '<div id="' + cardId + '" class="card"></div>'
            ).appendTo(view.body);
            var card = $('<div class="card-body"></div>').appendTo(cardCont);
            var label = getParamSettings(
              {object: object, objectSub: objectSub},
              'label'
            );
            card.append(
              '<div class="d-flex justify-content-between align-items-center mb-2"><h5 class="card-title ui-sortable-handle context">' +
                label +
                '</h5></div>'
            );
            new buildTable(
              card,
              {
                view: view,
                object: object,
                objectSub: objectSub,
                noRegister: true,
                search: value.trim(),
                keepOverview: true,
              },
              ids
            );

            // add to navigator
            var contNav = $('<li class="nav-item"></li>').appendTo(view.nav);
            var anker = $(
              '<a class="nav-link" href="#' +
                cardId +
                '" data-mdb-smooth-scroll="smooth-scroll" data-mdb-container="#search_body">' +
                label +
                '</a>'
            ).appendTo(contNav);
            new mdb.SmoothScroll(anker[0], {duration: 100});
          });

          // add navigator scroller
          var navIdent = 'nav' + rand();
          view.body.attr({
            'data-mdb-spy': 'scroll',
            'data-mdb-target': '#' + navIdent,
          });
          view.nav.parent().attr('id', navIdent);
          new mdb.ScrollSpy(view.body[0], {offset: 150});
          new mdb.PerfectScrollbar(view.body[0], {suppressScrollX: true});
          view.nav.find('.nav-link.active').removeClass('active');
          view.nav.find('.nav-link:first').addClass('active');
        },
      });
    },
  });
}
